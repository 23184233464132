@font-face {
  font-family: 'HelveticaNeue';
  src: url('./assets/font/HelveticaNeue.ttc');
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/font/Roboto-Regular.ttf');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  word-break: break-word;
  scrollbar-width: none;
  -ms-overflow-style: none;
  font-family: 'Roboto';
  color: #fff;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.w-infinity{
	width: 100000px;
}

